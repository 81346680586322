import { BaseModuleProps, NextPageContent, PageContent } from '../../types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSiteData } from '../../providers/SiteDataProvider';
import styles from './Video.module.scss';

let awaitVideoPlayerInterval: number | undefined = undefined;

const awaitVideoPlayer = (cb: () => void) => {
    return setTimeout(() => {
        if (global.VideoPlayer) {
            console.log('VideoPlayer found');
            cb();
        } else {
            console.log('VideoPlayer NOT found');
            awaitVideoPlayer(cb);
        }
    }, 100) as unknown as number;
};

const Video: React.FC<
    Omit<BaseModuleProps, 'pageContent'> & {
        pageContent: Pick<PageContent['content'], 'text' | 'video' | 'id'>;
    }
> = ({ pageContent, moduleHead }) => {
    const { textUtil } = useSiteData();
    const [loaded, setLoaded] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const textContent = textUtil.formatText(pageContent.text);

    useEffect(() => {
        console.log('loaded', loaded);
        console.log('initialized', initialized);
        console.log('VideoPlayer', global.VideoPlayer);
        if (loaded && !initialized && global.VideoPlayer) {
            global.VideoPlayer.Collection.addPlayerById(
                'player_container_' + pageContent.id,
            );
            console.log('Video initialized');
            setInitialized(true);
        } else {
            /*setTimeout(() => {
                setLoaded(true);
            }, 500);*/
        }
    }, [loaded, initialized, pageContent?.id]);

    useEffect(() => {
        if (initialized && global.VideoPlayer) {
            const player = global.VideoPlayer.Collection.getPlayerById(
                `player_container_${pageContent.id}`,
            );

            try {
                player.switchVideo(pageContent.video);
            } catch (e) {
                debugger;
            }
        }
    }, [pageContent.video]);

    const videoContent = useMemo(() => {
        return (
            <div
                className={styles.root}
                dangerouslySetInnerHTML={{
                    __html: `<div
                                 id="player_container_${pageContent.id}"
                                 disable-auto-creation
                    mi24-video-player
                    video-id="${pageContent.video}"
                    player-id="ByWM1kNdUaKrE12mAW9F74"
                    channel-id="98683"
                    config-type="vmpro"
                    flash-path="https://e.video-cdn.net/v2/"
                    api-url="https://d.video-cdn.net/play"
                ></div>`,
                }}
            />
        );
    }, [pageContent.id]);

    useEffect(() => {
        awaitVideoPlayerInterval = awaitVideoPlayer(() => {
            setLoaded(true);
            console.log('setLoaded to true');
        });
        if (!document.getElementById('mi-video')) {
            const s = document.createElement('script');
            s.src = 'https://e.video-cdn.net/v2/embed.js';
            s.id = 'mi-video';
            s.onload = () => {
                console.log('Video script loaded');
            };
            document.head.append(s);
        }

        return () => {
            clearTimeout(awaitVideoPlayerInterval);
        };
    }, []);

    return (
        <div
            className="m-text-image m-text-image--image-right clearfix"
            data-t-name="TextImage"
        >
            {moduleHead}
            <div
                className="m-text-image__image "
                data-t-lightbox="0e120491a5708401181c9b7287347f72eb983e88ab562e37dfa8a9b7d23407d6"
            >
                {videoContent}
                <p>&nbsp;</p>
                <div dangerouslySetInnerHTML={{ __html: textContent }} />
            </div>
        </div>
    );
};

export default Video;
